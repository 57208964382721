import trap from 'ci-trap';
import EventEmitter from 'events';

class Sentinel extends EventEmitter {
  constructor() {
    super();

    // Set up state
    this.reset();

    // Initialize Trap + Sentinel
    this.initialize(document, {
      /* eslint-disable no-underscore-dangle */
      apiKeyName: 'GRABOXY-API-KEY',
      apiKeyValue: window.__RUNTIME_CONFIG__.GRABOXY_API_KEY,
      trapUrl: window.__RUNTIME_CONFIG__.GRABOXY_TRAP_URL,
      sentinelInterval: window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_INTERVAL,
      sentinelMaxFailures:
        window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_MAX_FAILURES,
      sentinelRequiredResults:
        window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_REQUIRED_RESULTS,
      sentinelUrl: window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_URL,
      trapBufferSizeLimit:
        window.__RUNTIME_CONFIG__.GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
      trapBufferTimeout:
        window.__RUNTIME_CONFIG__.GRABOXY_TRAP_BUFFER_TIMEOUT,
      /* eslint-enable no-underscore-dangle */
    });
  }

  initialize(document, config) {
    this._config = {
      ...config,
      ...this._config,
    };

    trap.mount(document);
    trap.apiKeyName(this.config.apiKeyName);
    trap.apiKeyValue(this.config.apiKeyValue);
    trap.url(this.config.trapUrl);
    trap.bufferSizeLimit(this.config.trapBufferSizeLimit);

    // TODO: https://redmine.cursorinsight.com/issues/8579
    trap.bufferTimeout(this.config.trapBufferTimeout
      ? parseInt(this.config.trapBufferTimeout, 10)
      : undefined);
  }

  get score() {
    if (!this._results) { return 0.0; }
    if (this._results.length === 0) { return 0.0; }
    return this._results[this._results.length - 1].score;
  }

  set result(result) {
    this._results.push(result);
  }

  get config() {
    return this._config;
  }

  reset() {
    this._results = [];
  }

  start() {
    this.reset();

    const t = this;
    this._interval = setInterval(
      () => { t.classify(); },
      this.config.sentinelInterval,
    );
  }

  stop() {
    if (this._interval !== null) {
      clearInterval(this._interval);
    }
    this._interval = null;
  }

  // eslint-disable-next-line class-methods-use-this
  get isRunning() {
    return (!!this._interval);
  }

  classify() {
    this.submit({
      sessionId: trap.sessionId(),
      streamId: trap.streamId(),
    }).then((result) => {
      this.result = result;
      this.emit('classify', result);
    }).catch(() => {});
  }

  async submit(params) {
    const body = JSON.stringify(params);

    // Set up content-type and its optional arguments: API key and envelope
    // encoding
    let contentType = 'text/plain';
    if (typeof this.config.apiKeyName === 'string'
      && this.config.apiKeyName !== ''
      && typeof this.config.apiKeyValue === 'string'
      && this.config.apiKeyValue !== '') {
      contentType += '; '
        + `${this.config.apiKeyName}=`
        + `${this.config.apiKeyValue}`;
    }
    contentType += '; encoding=json';

    const response = await fetch(this.config.sentinelUrl, {
      // *GET, POST, PUT, DELETE, etc.
      method: 'POST',

      // no-cors, *cors, same-origin
      mode: 'cors',

      // *default, no-cache, reload, force-cache, only-if-cached
      cache: 'no-cache',

      // include, *same-origin, omit
      credentials: 'omit',

      // necessary HTTP headers
      headers: {
        // No other headers are allowed in a preflight-less CORS request
        // For details: https://developer.mozilla.org/en-US/docs/Glossary/CORS-safelisted_request_header
        'Content-Type': contentType,
      },

      // manual, *follow, error
      redirect: 'follow',

      // no-referrer, *no-referrer-when-downgrade, origin,
      // origin-when-cross-origin, same-origin, strict-origin,
      // strict-origin-when-cross-origin, unsafe-url
      referrerPolicy: 'origin',

      // data to be sent
      body,
    });

    if (!response.ok) {
      const message = `An error occured: ${response.status}`;
      throw new Error(message);
    }

    // If OK, then parse and return the JSON result
    return response.json();
  }

  validateTransaction() {
    const numberOfResults = this._results.length;
    const failures = this._results.filter(
      ({ score, threshold }) => score > threshold,
    ).length;
    return numberOfResults >= this.config.sentinelRequiredResults
      && failures <= this.config.sentinelMaxFailures;
  }

  // eslint-disable-next-line class-methods-use-this
  get getInfoJSON() {
    const object = {
      date: new Date(),
      sessionId: trap.sessionId(),
      streamId: trap.streamId(),
    };

    return JSON.stringify(object);
  }
}

// Export a singleton only
const sentinel = new Sentinel();

export default sentinel;
