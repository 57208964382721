import { MemoryRouter, Routes, Route } from 'react-router-dom';

import Scene from './components/Scene';
import CopyInfo from './components/CopyInfo';

import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Step4 from './pages/Step4';
import Step5 from './pages/Step5';

const App = () => (
  <Scene>
    <MemoryRouter>
      <Routes>
        <Route exact path="/" element={<Step1 />} />
        <Route exact path="/step2" element={<Step2 />} />
        <Route exact path="/step3" element={<Step3 />} />
        <Route exact path="/step4" element={<Step4 />} />
        <Route exact path="/step5" element={<Step5 />} />
      </Routes>
    </MemoryRouter>
    <CopyInfo/>
  </Scene>
);

export default App;
