import sentinel from '../GSR/sentinel';

const copyToClipboard = () => {
  navigator.clipboard.writeText(sentinel.getInfoJSON);
};

const CopyInfo = () => (
  <div id="copy-info">
    <button onClick={copyToClipboard}>
      ✂
    </button>
  </div>
);

export default CopyInfo;
