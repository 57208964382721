import { NavLink } from 'react-router-dom';

import Browser from '../../components/Browser';
import Menu from '../../components/Menu';
import sentinel from '../../components/GSR/sentinel';

const Step1 = () => (
  <>
    <Menu activeStep={0} />
    <Browser>
      <NavLink to="/step2" onClick={() => sentinel.start()}>
        <button className="btn btn-primary mb-16">Start Demo</button>
      </NavLink>
    </Browser>
  </>
);

export default Step1;
