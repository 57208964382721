import { NavLink, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

import Browser from '../../components/Browser';
import Menu from '../../components/Menu';

const Step2 = () => {
  // Set up form controls
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm();

  // Watch for "newRecipientToggle" changes
  const watchNewRecipientToggle = watch('newRecipientToggle', false);

  const navigate = useNavigate();

  const sourceAccounts = [
    {
      value: 'EUR - 12345678-12345678 - €12345',
      label: 'EUR - 12345678-12345678 - €12345',
    },
    {
      value: 'USD - 12345678-90123456 - $7890',
      label: 'USD - 12345678-90123456 - $7890',
    },
  ];

  const existingRecipientAccounts = [
    { value: '12345678-98765432 - Jakab', label: '12345678-98765432 - Jakab' },
    { value: '12345678-00189444 - Bence', label: '12345678-00189444 - Bence' },
    { value: '12345678-17714560 - Peter', label: '12345678-17714560 - Peter' },
  ];

  const validateAmountChange = (amount, { sourceAccount }) => {
    const max = parseInt(sourceAccount.match(/[$€](\d{1,})/)[1], 10);
    return amount <= max;
  };

  return (
    <>
      <Menu activeStep={1} />
      <Browser>
        <div className="card w-2/4 bg-base-100 shadow-xl mb-16">
          <div className="card-body">
            <h2 className="card-title">Transaction Details</h2>
            <label className="form-control">
              <div className="label">
                <span className="label-text">From</span>
              </div>
              <Controller
                control={control}
                name="sourceAccount"
                render={({ field }) => (
                  <Select
                    className="my-react-select-container"
                    classNamePrefix="my-react-select"
                    inputRef={field.ref}
                    isClearable
                    isSearchable
                    options={sourceAccounts}
                    value={sourceAccounts.find((c) => c.value === field.value)}
                    onChange={(a) => field.onChange(a?.value)}
                  />
                )}
              />
            </label>
            <label className="form-control">
              <div className="label">
                <span className="label-text">Amount</span>
              </div>
              <input
                {...register('amount', { validate: validateAmountChange })}
                placeholder="Enter amount"
                className={
                  `input input-bordered ${errors.amount && 'text-red-500'}`
                }
                aria-invalid={errors.amount ? 'true' : 'false'}
                autoComplete="off"
              />
              {errors.amount && (
                <p className="text-red-500 mt-2">
                  Insufficient funds.
                </p>
              )}
            </label>
            <div className="form-control">
              <div className="label">
                <span className="label-text">Recipient details</span>
              </div>
              <label className="label cursor-pointer justify-start">
                <span className="label-text">Existing recipient</span>
                <input
                  type="checkbox"
                  {...register('newRecipientToggle')}
                  className="toggle mx-2"
                />
                <span className="label-text">New recipient</span>
              </label>
              {
                watchNewRecipientToggle ? (
                  <div className="flex justify-between">
                    <label>
                      <input
                        type="text"
                        {...register('newRecipientName')}
                        placeholder="Name"
                        className="input input-bordered w-full"
                        autoComplete="off"
                      />
                    </label>
                    <label>
                      <input
                        type="text"
                        {...register('newRecipientAccount')}
                        placeholder="Account number"
                        className="input input-bordered w-full"
                        autoComplete="off"
                      />
                    </label>
                  </div>
                ) : (
                  <Controller
                    control={control}
                    name="existingRecipientAccount"
                    render={({ field }) => (
                      <Select
                        className="my-react-select-container"
                        classNamePrefix="my-react-select"
                        inputRef={field.ref}
                        isClearable
                        isSearchable
                        options={existingRecipientAccounts}
                        onChange={(r) => field.onChange(r?.value)}
                      />
                    )}
                  />
                )
              }
            </div>
            <div className="card-actions justify-end mt-5 pt-5">
              <button
                className="btn btn-primary"
                onClick={
                  handleSubmit(
                    (formData) => navigate('/step3', { state: formData }),
                  )
                }
              >
                Preview Transaction
              </button>
              <NavLink to="/">
                <button className="btn">Cancel</button>
              </NavLink>
            </div>
          </div>
        </div>
      </Browser>
    </>
  );
};

export default Step2;
